<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="main-title mb-0">{{ $t("Educational History") }}</h2>
      </v-col>
      <v-col :class="currentAppLocale == 'ar' ? 'text-left' : 'text-right'">
       
          <v-icon class="main-color-back" large @click="print" :title="$t('Print')" v-if="showTable">print</v-icon>
               

        <!-- ================== Activity logs ================== -->
        <v-btn
          icon
          to="/systemlogging/educational_history"
          target="_blank"
          v-if="activitylogPermission"
        >
          <v-icon class="main-color" large :title="$t('System Log')"
            >mdi-archive-clock</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>

    <v-form ref="selectForm" v-model="selectvalid">
      <v-row>
        <v-col>
          <label for="academicPeriods">{{ $t("Academic Period") }}</label>
          <v-select
            v-model="selectedPeriod"
            :rules="selectValidation"
            :items="academicPeriods"
            item-text="name"
            item-value="id"
            label="academicPeriods"
            @change="getQuarterAndGrades()"
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <label for="Quarters">{{ $t("Quarters") }}</label>
          <v-select
            v-model="selectedQuarter"
            :items="quarters"
            item-text="name"
            item-value="id"
            label="Quarters"
            @change="showTable = false"
            :rules="selectValidation"
            required
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <label for="Grades">{{ $t("Grades") }}</label
          ><v-select
            v-model="selectedGrade"
            :items="grades"
            :rules="selectValidation"
            item-text="name"
            item-value="id"
            label="Grades"
            solo
            dense
            @change="getClasses"
          ></v-select
        ></v-col>
        <v-col>
          <label for="Classes">{{ $t("Classes") }}</label>
          <v-select
            v-model="selectedClass"
            :rules="selectValidation"
            :items="classes"
            @change="showTable = false"
            item-text="name"
            item-value="id"
            label="Classes"
            solo
            dense
          ></v-select>
        </v-col>

        <v-col >
          
          <v-icon class="main-color-back mt-10" large @click="getResults()"
            :loading="loading" :disabled="loading" :title="$t('Filter')">search</v-icon>
            <v-icon :title="$t('Activate')" class="main-color-back  mt-10" large @click="openConfirmDialog" 
              v-if="show && showTable && canActive">done_all</v-icon>
          <v-icon class="main-color-back-red  mt-10" large :title="$t('Deactivate')" @click="deActivate" :loading="loading" 
          :disabled="loading" v-if="show && showTable && canActive">remove_done</v-icon>
          
        
          
          
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col v-if="showTable == true">
        <v-data-table
          :headers="headers"
          :items="students"
          v-model="selected"
          class="elevation-1 level1"
          :items-per-page="10000"
          hide-default-footer
          item-key="student_id"
          :show-select="true"

        >
          <!-- datatable custom header on datatable -->
          <template
            v-for="(h, index) in headers"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            <p v-if="h.subject_id" :key="index">
              <router-link
                :to="
                  `/educationalhistory/subjectview?academic_id=${selectedPeriod}&quarter_id=${selectedQuarter}&grade_id=${selectedGrade}&class_id=${selectedClass}&class_name=${selectedClassName}&subject_id=${h.subject_id}`
                "
                >{{ h.text }}
                <span v-if="h.total_mark > 0"
                  >({{ h.total_mark }})</span
                ></router-link
              >
              {{ h.availability_text }}
            </p>
            <p v-else>{{ h.text }}</p>
          </template>

          <template
            v-for="header in headers"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <div
              :key="header.value"
              v-if="header.value != 'student_name' && header.value != 'total'"
            >
              <p
                v-if="
                  header &&
                    header.passing_mark &&
                    Number(item[header.value]) >= Number(header.passing_mark)
                "
                class="passed mb-0"
              >
                {{ item[header.value] }}
              </p>
              <p
                v-if="
                  header &&
                    header.passing_mark &&
                    Number(item[header.value]) < Number(header.passing_mark)
                "
                class="faild mb-0"
              >
                {{ item[header.value] }}
              </p>

              <p
                v-if="
                  (header && !header.passing_mark) ||
                    item[header.value] == 'N/A'
                "
                class="unAssignedMark mb-0"
              >
                {{ item[header.value] }}
              </p>
              <p
                v-if="
                  (header && !header.passing_mark) ||
                    item[header.value] == 'N/A'
                "
                class="unAssignedMark mb-0"
              >
                {{ item[header.value] }}
              </p>
            </div>
            

            <div :key="header.value" v-if="header.value == 'total'">
              {{ total[header.value] }}
            </div>
            <!-- zzzzzzz {{ item }} -->
            <!-- <div :key="header.value" v-if="header.value == 'status'">
              {{ item[header.value] }}
            </div> -->
            <!-- sssssssssssssssss -->
          </template>

          <template v-slot:item.status="{ item }">
            {{item.status}}
          </template>

          <template v-slot:item.student_name="{ item }">
            <StudentHoverMenu
              :studentName="item.student_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              v-if="item.student_id"
            ></StudentHoverMenu>
            <span v-else>{{ item.student_name }} == {{ item.student_id }}</span>
          </template>

          <template v-slot:item.total="{ item }">
            {{ item.total }}
          </template>
        </v-data-table>
      </v-col>

      <v-col v-else>
        <v-alert type="info" v-if="noData == false">
          {{ $t("Click filter button to filter") }}
        </v-alert>
        <v-alert type="info" v-else>
          {{ $t("No data available") }}
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog
      v-model="ConfirmDialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text class="pa-5">{{
          $t(
            "student/parents will get notified about the results, are you sure you want to activate ?"
          )
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            id="yesBtn"
            @click="activate"
            :loading="loading"
            :disabled="loading"
            class="modal-btn-save yesBtn"
            >{{ $t("Activate Now") }}</v-btn
          >
          <v-btn
            class="modal-btn-cancel noBtn"
            @click="ConfirmDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="closeSnack"
    ></snackComponent>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import StudentHoverMenu from "@/components/Students/StudentHoverMenu";
import snackComponent from "@/components/snackComponent";
export default {
  name: "adminView",
  components: { StudentHoverMenu, snackComponent },

  data() {
    return {
      selected:[],
      activitylogPermission: ACL.checkPermission("activitylog"),
      canActive: ACL.Role("Super-Admin") ||ACL.Role("Admin"),
      loading: false,
      snackbar: false,
      snackColor: "red",
      snackMsg: "",
      ConfirmDialog: false,
      pdfUrl: "",
      total: [],
      headers: [],

      students: [],
      ids:[],
      studentIds:[],
      showTable: false,
      selectvalid: false,
      firstLoad: false,
      noData: false,

      selectValidation: [v => !!v || "This field is required"],

      quarters: [],
      grades: [],
      classes: [],
      subjects: [],
      academicPeriods: [],
      edu_ids: [],
      isActive: false,
      show: false,
      selectedQuarter: "",
      selectedGrade: "",
      selectedClass: "",
      selectedClassName: "",
      selectedSubject: "",
      selectedPeriod: "",
      backNavigation: false
    };
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    openConfirmDialog() {
      this.ConfirmDialog = true;
    },
    activate() {
      if (this.selected.length == 0) {
        this.ConfirmDialog = false;
        this.snackbar = true;
        this.snackColor = "red";
        this.snackMsg = this.$i18n.t("Please choose Student first");
        setTimeout(() => {
          this.snackbar = false;
        }, 2000);
      } else {
        this.ids =[];
        this.selected.forEach((item) => {
          this.ids.push(item.student_id);
        });
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/educational-history/admin/activated",
          {
            edu_ids: this.edu_ids,
            grade_id: this.selectedGrade,
            quarter_id: this.selectedQuarter,
            class_id: this.selectedClass,
            students: this.ids
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.isActive = true;
            this.ConfirmDialog = false;
            this.ids = [];
            this.selected = [];
            
            this.getResults();
          } else {
            this.snackbar = true;
            this.snackColor = "red";
            this.snackMsg = response.data.status.message;
            this.ConfirmDialog = false;

            setTimeout(() => {
              this.snackbar = false;
            }, 2000);
          }
        });
      }
    },
    deActivate() {
      if (this.selected.length == 0) {
        this.ConfirmDialog = false;
        this.snackbar = true;
        this.snackColor = "red";
        this.snackMsg = this.$i18n.t("Please choose Student first");
        setTimeout(() => {
          this.snackbar = false;
        }, 3000);
      } else {
        this.ids = [];
        this.selected.forEach((item) => {
          this.ids.push(item.student_id);
        });
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/educational-history/admin/deActivate",
          {
            edu_ids: this.edu_ids,
            grade_id: this.selectedGrade,
            quarter_id: this.selectedQuarter,
            class_id: this.selectedClass,
            students: this.ids
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.isActive = false;
            this.ids = [];
            this.selected = [];
            this.getResults();
          }
        });
      }
    },
    print() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/admin/overview/allSubjects/print?academic_period_id=" +
            this.selectedPeriod +
            "&grade_id=" +
            this.selectedGrade +
            "&class_id=" +
            this.selectedClass +
            "&quarter_id=" +
            this.selectedQuarter,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
    
    getacademicPeriods() {
      this.noData = false;
      this.showTable = false;
      this.selectedPeriod = "";
      this.academicPeriods = [];
      axios
        .get(this.getApiUrl + "/educational-history/filter/academicPeriods", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.academicPeriods = response.data.data;
          if (this.academicPeriods && this.academicPeriods.length > 0) {
            this.academicPeriods.forEach(period => {
              if (period.is_active == 1) {
                if (this.backNavigation == false) {
                  this.selectedPeriod = period.id;
                } else {
                  this.selectedPeriod = Number(this.$route.query.period_id);
                }
              }
            });
            this.getQuarterAndGrades();
          } else {
            // this.students = [];
            // this.headers = [];
          }
        });
    },
    getQuarterAndGrades() {
      this.noData = false;
      this.getQuarters();
      this.getGrades();
    },
    getQuarters() {
      this.noData = false;
      this.showTable = false;
      this.selectedQuarter = "";
      axios
        .get(
          this.getApiUrl +
            "/educational-history/filter/quarters?academic_period_id=" +
            this.selectedPeriod,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.quarters = response.data.data;
          if (this.quarters && this.quarters.length > 0) {
            if (this.backNavigation == false) {
              this.selectedQuarter = this.quarters[0].id;
            } else {
              this.selectedQuarter = Number(this.$route.query.quarter_id);
            }
          }
        });
    },
    getGrades() {
      this.noData = false;
      this.showTable = false;
      this.selectedGrade = "";
      axios
        .get(
          this.getApiUrl +
            "/educational-history/filter/grades/0?academic_period_id=" +
            this.selectedPeriod +
            "&role=admin",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.grades = response.data.data;
          if (this.grades && this.grades.length > 0) {
            if (this.backNavigation == false) {
              this.selectedGrade = this.grades[0].id;
            } else {
              this.selectedGrade = Number(this.$route.query.grade_id);
            }
            this.getClasses();
          }
        });
    },
    getClasses() {
      this.noData = false;
      this.showTable = false;
      this.selectedClass = "";
      axios
        .get(
          this.getApiUrl +
            "/educational-history/filter/classes/" +
            this.selectedGrade +
            "?academic_period_id=" +
            this.selectedPeriod,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.classes = response.data.data;

          if (this.classes && this.classes.length > 0) {
            if (this.backNavigation == false) {
              this.selectedClass = this.classes[0].id;
              this.selectedClassName = this.classes[0].name;
            } else {
              this.selectedClass = Number(this.$route.query.class_id);
              this.selectedClassName = this.$route.query.class_name;
            }
            if (this.firstLoad == false) {
              this.getResults();
            }

            this.firstLoad = true;
          }
        });
    },

    getResults() {
      this.classes.forEach(classObj => {
        if (classObj.id == this.selectedClass) {
          this.selectedClassName = classObj.name;
        }
      });
      setTimeout(() => {
        if (this.selectvalid == true) {
          this.loading = true;
          axios
            .get(
              this.getApiUrl +
                "/educational-history/admin/overview/allSubjects?academic_period_id=" +
                this.selectedPeriod +
                "&quarter_id=" +
                this.selectedQuarter +
                "&grade_id=" +
                this.selectedGrade +
                "&class_id=" +
                this.selectedClass,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              this.showTable = true;
              this.students = response.data.data.students;
              this.headers = response.data.data.headers;
              this.edu_ids = response.data.data.edu_ids;
              this.isActive = response.data.data.is_active;
              this.show = this.edu_ids.length > 0 ? true : false;
              this.ids = [];
              this.selected = [];

              /* calculate total on frontend */
              // this.students.forEach(student => {
              //   student.total = 0;
              //   for (const property in student) {
              //     if (
              //       property != "student_id" &&
              //       property != "student_name" &&
              //       property != "user_id" &&
              //       property != "total" &&
              //       property != "status" 
              //     ) {
              //       student.total +=
              //         Math.round(Number(student[property]) * 100) / 100;
              //     }
                  
              //   }
              // });

              this.backNavigation = false;
              this.headers.unshift({
                text: this.$i18n.t("Student Name"),
                value: "student_name"
              });
              this.headers.push({
                text: this.$i18n.t("Status"),
                value: "status"
              });
              this.headers.push({
                text: this.$i18n.t("Total"),
                value: "total"
              });

              this.academicPeriodsMark = this.headers[0].total_mark;
              // this.updateacademicPeriodsHeaders();

              if (this.students && this.students.length == 0) {
                this.noData = true;
              }
              if (this.students && this.students[0].values[0]) {
                this.edit = false;
              }
            });
        } else {
          this.$refs.selectForm.validate();
          this.noData = true;
        }
      }, 200);
    }
  },
  mounted() {
    if (this.$route.query.class_id) {
      this.backNavigation = true;
    }
    this.getacademicPeriods();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.main-title {
  color: gray;
  font-weight: 700;
}

.icon-btn {
  background-color: $main-color;
}

.green-btn {
  background-color: #4caf50 !important;
  margin: 0rem 1rem;
}

.passed {
  color: green;
}
.faild {
  color: red;
}
.main-color-back {
  color: #7297ff !important;
  //color: #ffffff !important;
  //border: 2px solid #7297ff !important;
  margin-right: 20px !important;
}
.main-color-back-red{
  color: #e02b2b !important;
    //color: #ffffff !important;
    //border: 2px solid #e02b2b !important;
    margin-right: 20px !important;
  
}
.text-left{
  text-align: left !important;
}
</style>
